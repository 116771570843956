import React from "react";
import {
  Link
} from 'react-router-dom';
// Importing resources.
import Transitions from "../components/nav/Transition";
// Import assets.
import AboutImage from "../assets/images/about/about.jpg";

const AboutUsPage = () => {
    return(
        <>
            <Transitions>
                <section className="breadcrumbs">
                    <div className="container">
                        <div className="d-flex justify-content-between align-items-center">
                            <h2>About Us</h2>
                            <ol>
                                <li><Link to="/">Home</Link></li>
                                <li>About Us</li>
                            </ol>
                        </div>
                    </div>
                </section>
                <section className="about" /*data-aos="fade-up"*/>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <img src="https://www.justsportsug.org/assetweb/images/aboutjsu.jpg" className="img-fluid" alt="About Just Sports" />
                            </div>
                            <div className="col-lg-6 pt-4 pt-lg-0">
                                <h3>Who we are.</h3>
                                {/* <p className="fst-italic">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
                                magna aliqua.
                                </p>. */}
                                <h4>Our Vision.</h4>
                                <p className="fst-italic">
                                    To create a platform for advocacy on legal issues in the sports sector in Uganda and Africa at large.
                                </p>
                                <h4>Our Mission.</h4>
                                <p className="fst-italic">
                                    Using the law as a tool of advocacy for a better sports industry through research and publication.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="about" /*data-aos="fade-up"*/>
                    <div className="container">
                        <div className="row">
                            <h4>Our Objectives.</h4>
                                <ul>
                                    <li><i className="bi bi-check2-circle"></i>To use advocacy to lobby for a better legal framework over the sports sector in Uganda.</li>
                                    <li><i className="bi bi-check2-circle"></i>To work in with other stakeholders to assert for accountability and transparency in all sports related processes.</li>
                                    <li><i className="bi bi-check2-circle"></i>To use advocacy to create awareness about legally related rights of athletes.</li>
                                    <li><i className="bi bi-check2-circle"></i>To offer legal assistance to athletes.</li>
                                    <li><i className="bi bi-check2-circle"></i>To publish and drive the conversation about legal developments influencing the sports sector.</li>
                                    <li><i className="bi bi-check2-circle"></i>To create and develop a network of proactive sports law advocates in Uganda.</li>
                                </ul>
                        </div>
                    </div>
                </section>
            </Transitions>
        </>
    );
}

export default AboutUsPage;