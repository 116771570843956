// Import page sections.
import Navbar from './components/nav/Navbar';
import PageFormat from './pages/PageFormat';
import Footer from './components/footer/Footer';
// Import assets.
import './assets/css/style.css';
import './assets/css/articlestyle.css';
// import FormFeedback from './components/models/FormFeedback';

function App() {
  return (
    <div>
      <Navbar />
      <PageFormat />
      <Footer />
      {/* <FormFeedback /> */}
      {/* <a href="#" className="back-to-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short"></i></a> */}
      {/* The above line of code is not yet working. */}
    </div>
  );
}

export default App;
