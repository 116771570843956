import React, { useState, useEffect } from 'react';
import axios from 'axios';
// Page elements.
// import CommentReply from './CommentReply';
import Comment from './Comment';
import CommentForm from '../../../contact/forms/CommentForm';

const CommentSection = (props) => {

    const [artComments, setArtComments] = useState([]);
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        // const fetchData = async () =>{
        //     setLoading(true);
        //     try {
        //       const {data: response} = await axios.get("http://127.0.0.1:8000/api/comments/"+props.replyTo);
        //       setArtComments(response);
        //     } catch (error) {
        //       window.alert(error)
        //     }
        //     setLoading(false);
        //   }
        //   fetchData();

        axios
            .get(`http://127.0.0.1:8000/api/comments/${props.replyTo}`)
            .then((comresult) => {
            // console.log("Author Feedback: "+(comresult.data[0])[0].updated_at);
            setArtComments(comresult.data[0]);
            // window.alert("Comment:"+comresult)
        }).catch((error) => console.log(error));
    }, []);

    const commentNo = artComments.length;

    function noComments(artNo) {
        if(artNo > 0){
            return (
                <>
                    {/* {artComments.map(value => (
                        <Comment
                            name={value.name}
                            comment={value.comment}
                            date={value.date}
                            id={value.comid}
                        />
                    ))} */}
                </> 
            )
        }else{
            return (
                <div className="section-title">
                        <p>
                            <b>No comments yet.</b> Be the first to comment below.
                        </p>
                </div>
            )
        }
    }

    return (
        <div className="blog-comments">
            <h4 className="comments-count">{`${commentNo} Comments`}</h4>
            {noComments(commentNo)}
            <CommentForm
                index={commentNo+1}
                replyTo={props.replyTo}
            />
        </div>
    );
}

export default CommentSection;