import React from "react";
import {
  Link
} from 'react-router-dom';
// Importing resources.
// import Transitions from "../components/nav/Transition";

const PageNotFound = () => {
    return(
        <div style={{height: 300, marginTop: 250}} className="section-title">
            <h2 style={{color: 'red'}}>Page Not Found!!!</h2>
            <p>
                Sorry. The  page you are looking for seems not to exist. Please click the link below to be redirected to the homepage.<br/>
                <Link to="/">Click Here to go to the Homepage.</Link>
            </p>
        </div>
    );
}

export default PageNotFound;