import React, { useContext } from "react";
import {
  Link,
  useParams,
} from 'react-router-dom';
// Importing resources.
import { Articles } from './Articles';
import NoImage from "../../../assets/images/noimage.png"; // To be removed soon.

// Import requirements for the context API.
import { /*UserProvider,*/ UserContext, UserDispatchContext } from "../../../context/UserProvider";

const Article = (props) => {

    const { userDetails, sportsAuthors, artCategories } = useContext(UserContext);
    const { setUserDetails, setSportsAuthors, setArtCategories } = useContext(UserDispatchContext);
    const selectArt = Articles.filter(art => art.keyvalue === props.keyval);
    const artAuth = sportsAuthors.filter(auth => auth.authorid === selectArt[0].author);
    // console.log("Context: "+userDetails.username);

    return(
        <article className="entry">
            <div style={{textAlign: "center"}} className="entry-img">
                <img src={props.picture} alt={props.title} className="img-fluid" />
            </div>
            <h2 className="entry-title">
                <Link to={"/publications/article/"+props.keyval}>{props.title}</Link>
            </h2>
            <div className="entry-meta">
                <ul>
                    <li className="d-flex align-items-center"><i className="bi bi-person"></i> <Link to={"/publications/article/"+props.keyval}>{artAuth[0].name}</Link></li>
                    <li className="d-flex align-items-center"><i className="bi bi-clock"></i> <Link to={"/publications/article/"+props.keyval}><time datetime="2023-02-06">{props.published}</time></Link></li>
                    <li className="d-flex align-items-center"><i className="bi bi-chat-dots"></i> <Link to={"/publications/"+props.catval}>{props.category}</Link></li>
                    {/* <li className="d-flex align-items-center"><i className="bi bi-chat-dots"></i> <a href="blog-single.html">No Comments yet</a></li> */}
                </ul>
            </div>
            <div className="entry-content">
                <p>
                    {(selectArt[0].audio).substring(0,280)}...
                </p>
                <div className="read-more">
                    <Link to={"/publications/article/"+props.keyval}>Read More</Link>
                </div>
            </div>
        </article>
    );
}

export default Article;