import React from "react";
import {
  Link,
  NavLink
} from 'react-router-dom';
import NavLogo from "../../assets/images/newlogo.jpg";

const Navbar = () => {

    // on('click', '.mobile-nav-toggle', function(e) {
    //     select('#navbar').classList.toggle('navbar-mobile')
    //     this.classList.toggle('bi-list')
    //     this.classList.toggle('bi-x')
    // })

    const navToggle = (e) => {
        const navElement = document.getElementById('navbar');
        const navButton = document.getElementById('nav-toggle-button');
        navElement.classList.toggle('navbar-mobile')
        navButton.classList.toggle('bi-list')
        navButton.classList.toggle('bi-x')
	}

    // on('click', '.scrollto', function(e) {
    //     if (select(this.hash)) {
    //       e.preventDefault()
    
    //       let navbar = select('#navbar')
    //       if (navbar.classList.contains('navbar-mobile')) {
    //         navbar.classList.remove('navbar-mobile')
    //         let navbarToggle = select('.mobile-nav-toggle')
    //         navbarToggle.classList.toggle('bi-list')
    //         navbarToggle.classList.toggle('bi-x')
    //       }
    //       scrollto(this.hash)
    //     }
    //   }, true)

    return(
        <header id="header" className="fixed-top d-flex align-items-center ">
            <div className="container d-flex justify-content-between align-items-center">

                <div className="logo">
                    {/* <h1 className="text-light"><a href="index-2.html"><span>Just Sports</span></a></h1> */}
                    {/* Uncomment below if you prefer to use an image logo */}
                    {/* <a href="index.html"><img src="assets/img/logo.png" alt="" className="img-fluid"></a> */}
                    <Link to="/">
                        <img src={NavLogo} alt="" className="img-fluid" />
                        <span className="text-light nav-title">Just Sports</span>
                    </Link>
                </div>

                <nav id="navbar" className="navbar">
                    <ul>
                        <li><NavLink to="/">Home</NavLink></li>
                        <li><NavLink to="publications">Our Publications</NavLink></li>
                        <li><NavLink to="projects">Our Projects</NavLink></li>
                        {/* <li><a href="team.html">Our Authors</a></li> */}
                        <li><NavLink to="about-us">About Us</NavLink></li>
                        {/* <li><NavLink>Our Projects</NavLink></li> */}
                        <li><NavLink to="contact-us">Contact Us</NavLink></li>
                    </ul>
                    <i id="nav-toggle-button" className="bi bi-list mobile-nav-toggle" onClick={navToggle}></i>
                </nav>

            </div>
        </header>
    );
}

export default Navbar;