import React, { useState, useEffect, useContext } from 'react';
import {
  Link,
  useParams,
} from 'react-router-dom';
// Importing page sections.
import ProjBanner from "../../components/banner/ProjBanner";
// Importing resources.
import { Projects } from '../../components/projects/Projects';

// Import requirements for the context API.
import { UserContext, UserDispatchContext } from "../../context/UserProvider";

const ProjectDetailsPage = () => {

  const { projId } = useParams();
//   const { sportsAuthors, artCategories, playingAudio } = useContext(UserContext);
//   const { setPlayingAudio } = useContext(UserDispatchContext);

    // Projects.
    const selectPro = Projects.filter(pro => pro.keyv === projId);

    return (
        <>
            <ProjBanner
                name={selectPro[0].naame}
            />
            <section className="breadcrumbs">
                <div className="container">
                    <div className="section-title">
                        <h2>{selectPro[0].tiitle}</h2>
                    </div>
                    <div className="row">
                        {selectPro[0].desc}
                    </div>
                </div>
            </section>
          {/* <section id="blog" className="blog">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 entries">
                  <article className="entry entry-single">
                    <h2 className="entry-title">
                      <Link to={"/publications/article/"+selectArt[0].keyvalue}>{selectArt[0].title}</Link>
                    </h2>
                    <div style={{marginBottom: 40}} className="entry-meta">
                      <ul>
                        <li className="d-flex align-items-center"><i className="bi bi-person"></i> <Link to={"/publications/article/"+selectArt[0].keyvalue}>{artAuthor[0].name}</Link></li>
                        <li className="d-flex align-items-center"><i className="bi bi-clock"></i> <Link to={"/publications/article/"+selectArt[0].keyvalue}><time datetime="2023-02-06">{selectArt[0].published}</time></Link></li>
                    <li className="d-flex align-items-center"><i className="bi bi-chat-dots"></i> <Link to={"/publications/"+selectCat[0].keyval}>{selectCat[0].name}</Link></li>
                        <li className="d-flex align-items-center"><i className="bi bi-chat-dots"></i> <a href="blog-single.html">12 Comments</a></li>
                      </ul>
                    </div>    
                    <div style={{textAlign: "center"}} className="entry-img">
                      <img src={selectArt[0].image} alt={selectArt[0].title} className="img-fluid" />
                    </div>
                    <div className="entry-content">
                      <a className="btn-listen" onClick={() => speakArticle()}>{audioTrigger}</a>
                      {selectArt[0].article}
                    </div>
                  </article>
                </div>

        </div>

      </div>
    </section> */}
        </>
    );
}

export default ProjectDetailsPage;