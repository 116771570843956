import React from "react";
import Subscribe from './forms/Subscribe';

const Newsletter = () => {

    return(
        <div className="footer-newsletter">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <h4>Our Newsletter</h4>
                        <p>Please provide your email address to always be notified when we upload an article.</p>
                    </div>
                    <div className="col-lg-6">
                        <Subscribe />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Newsletter;