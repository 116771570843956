import React, { useState, useEffect, useContext } from 'react';
import {
  Link,
  useParams,
} from 'react-router-dom';
// Importing resources.
import { Articles } from '../../components/publications/articles/Articles';
import CommentSection from '../../components/publications/articles/comments/CommentSection';
// import '../../components/audioarticle/SpeakArticle';

// Import requirements for the context API.
import { UserContext, UserDispatchContext } from "../../context/UserProvider";

const FullArticlePage = () => {

  const [audioTrigger, setAudioTrigger] = useState(<><i style={{height: 70}} className="bi bi-play"></i> Listen to Article</>);
  const { artId } = useParams();
  const { sportsAuthors, artCategories, playingAudio } = useContext(UserContext);
  const { setPlayingAudio } = useContext(UserDispatchContext);

  useEffect(() => {
    if(playingAudio.playing){
      setAudioTrigger(<><i style={{height: 70}} className="bi bi-stop"></i> Stop Listenning</>);
    }
  }, []);

    // Articles.
    // const selectArt = Articles.filter(art => art.cat === selectCat[0].id);
    const selectArt = Articles.filter(art => art.keyvalue === artId);
    // Article category details.
    const selectCat = artCategories.filter(cat => cat.catid === selectArt[0].cat);
    // Author details.
    const artAuthor = sportsAuthors.filter(auth => auth.authorid === selectArt[0].author);

    // if(!(window.speechSynthesis.speaking)){
    //   const [playingAudio, setPlayingAudio] = useState(<><i style={{height: 70}} className="bi bi-play"></i> Listen to Article</>);
    // } else {
    //   const [playingAudio, setPlayingAudio] = useState(<><i style={{height: 70}} className="bi bi-stop"></i> Stop Listenning</>);
    // }

    // Text-to-Speech.
    const artRead = new SpeechSynthesisUtterance();
    const voices = window.speechSynthesis.getVoices();
    // console.log("Voice list: "+voices[5].name)
    const speakArticle = () => {
      // artRead.text = selectArt[0].article;
      artRead.text = `${selectArt[0].title} Written by ${artAuthor[0].name}. ${selectArt[0].audio}`;
      artRead.pitch = 0.5;
      artRead.voice = voices[2];
      if(playingAudio.playing){
        window.speechSynthesis.cancel();
        setPlayingAudio({
          playing: false,
          article: ""
        });
        setAudioTrigger(<><i style={{height: 70}} className="bi bi-play"></i> Listen to Article</>);
      }else{
        window.speechSynthesis.speak(artRead);
        setPlayingAudio({
          playing: true,
          article: `${selectArt[0].title}`
        });
        setAudioTrigger(<><i style={{height: 70}} className="bi bi-stop"></i> Stop Listenning</>);
      }
    }

    function selectedCategory(catId){
      const listCat = artCategories.filter(cat => cat.catid === catId);

      if(catId === selectCat[0].catid){
          return (
            <li className='selected-category'><Link to={"/publications/"+listCat[0].keyval}>{listCat[0].name}</Link></li>
          )
      }else {
          return (
            <li><Link to={"/publications/"+listCat[0].keyval}>{listCat[0].name}</Link></li>
          )
      }
    }

    return (
        <>
          <section className="breadcrumbs">
            <div className="container">
              <div className="d-flex justify-content-between align-items-center">
                {/* <h2>{selectArt[0].title}</h2> */}
                <h2>{selectCat[0].name}</h2>
                <ol>
                  <li><Link to="/">Home</Link></li>
                  <li><Link to="/publications">Our Publications</Link></li>
                  <li>{selectCat[0].name}</li>
                </ol>
              </div>
            </div>
          </section>
          <section id="blog" className="blog">
            <div className="container" /*data-aos="fade-up"*/>
              <div className="row">
                <div className="col-lg-8 entries">
                  <article className="entry entry-single">
                    <h2 className="entry-title">
                      <Link to={"/publications/article/"+selectArt[0].keyvalue}>{selectArt[0].title}</Link>
                    </h2>
                    <div style={{marginBottom: 40}} className="entry-meta">
                      <ul>
                        <li className="d-flex align-items-center"><i className="bi bi-person"></i> <Link to={"/publications/article/"+selectArt[0].keyvalue}>{artAuthor[0].name}</Link></li>
                        <li className="d-flex align-items-center"><i className="bi bi-clock"></i> <Link to={"/publications/article/"+selectArt[0].keyvalue}><time datetime="2023-02-06">{selectArt[0].published}</time></Link></li>
                    <li className="d-flex align-items-center"><i className="bi bi-chat-dots"></i> <Link to={"/publications/"+selectCat[0].keyval}>{selectCat[0].name}</Link></li>
                        {/* <li className="d-flex align-items-center"><i className="bi bi-chat-dots"></i> <a href="blog-single.html">12 Comments</a></li> */}
                      </ul>
                    </div>    
                    <div style={{textAlign: "center"}} className="entry-img">
                      <img src={selectArt[0].image} alt={selectArt[0].title} className="img-fluid" />
                    </div>
                    <div className="entry-content">
                      <a className="btn-listen" onClick={() => speakArticle()}>{audioTrigger}</a>
                      {selectArt[0].article}
                    </div>
                    {/* <div className="entry-footer">
                      <i className="bi bi-folder"></i>
                      <ul className="cats">
                        <li><a href="#">Business</a></li>
                      </ul>
                      <i className="bi bi-tags"></i>
                      <ul className="tags">
                        <li><a href="#">Creative</a></li>
                        <li><a href="#">Tips</a></li>
                        <li><a href="#">Marketing</a></li>
                      </ul>
                    </div> */}
                  </article>
                  {/* <div className="blog-author d-flex align-items-center">
                    <img src={Jeremiah} className="rounded-circle float-left" alt="" />
                    <div>
                      <h4>Okaka Jeremiah</h4>
                      <div className="social-links">
                        <a href=""><i className="bi bi-envelope"></i></a>
                      </div>
                      <p>
                        Itaque quidem optio quia voluptatibus dolorem dolor. Modi eum sed possimus accusantium. Quas repellat voluptatem officia numquam sint aspernatur voluptas. Esse et accusantium ut unde voluptas.
                      </p>
                    </div>
                  </div> */}
                  <CommentSection replyTo={selectArt[0].id} />

                </div>

          <div className="col-lg-4">

            <div className="sidebar article-sidebar">

              <h3 className="sidebar-title">Author Details.</h3>
              <div className="blog-author-details">
                <img src={artAuthor[0].avat} className="rounded-circle float-left" alt={artAuthor[0].name} />
                <h4>{artAuthor[0].name}</h4>
                {/* <a href=""><i className="bi bi-envelope"></i></a> */}
                {/* <h6>{artAuthor[0].email}</h6> */}
              </div>

              {/* <h3 className="sidebar-title">Categories</h3>
              <div className="sidebar-item categories">
                <ul>
                  <li><a href="#">General <span>(25)</span></a></li>
                  <li><a href="#">Lifestyle <span>(12)</span></a></li>
                  <li><a href="#">Travel <span>(5)</span></a></li>
                  <li><a href="#">Design <span>(22)</span></a></li>
                  <li><a href="#">Creative <span>(8)</span></a></li>
                  <li><a href="#">Educaion <span>(14)</span></a></li>
                </ul>
              </div> */}

              {/* <h3 className="sidebar-title">Recent Posts</h3>
              <div className="sidebar-item recent-posts">
                <div className="post-item clearfix">
                  <img src="assets/img/blog/blog-recent-1.jpg" alt="" />
                  <h4><a href="blog-single.html">Nihil blanditiis at in nihil autem</a></h4>
                  <time datetime="2020-01-01">Jan 1, 2020</time>
                </div>

                <div className="post-item clearfix">
                  <img src="assets/img/blog/blog-recent-2.jpg" alt="" />
                  <h4><a href="blog-single.html">Quidem autem et impedit</a></h4>
                  <time datetime="2020-01-01">Jan 1, 2020</time>
                </div>

                <div className="post-item clearfix">
                  <img src="assets/img/blog/blog-recent-3.jpg" alt="" />
                  <h4><a href="blog-single.html">Id quia et et ut maxime similique occaecati ut</a></h4>
                  <time datetime="2020-01-01">Jan 1, 2020</time>
                </div>

                <div className="post-item clearfix">
                  <img src="assets/img/blog/blog-recent-4.jpg" alt="" />
                  <h4><a href="blog-single.html">Laborum corporis quo dara net para</a></h4>
                  <time datetime="2020-01-01">Jan 1, 2020</time>
                </div>

                <div className="post-item clearfix">
                  <img src="assets/img/blog/blog-recent-5.jpg" alt="" />
                  <h4><a href="blog-single.html">Et dolores corrupti quae illo quod dolor</a></h4>
                  <time datetime="2020-01-01">Jan 1, 2020</time>
                </div>

              </div> */}

              <h3 className="sidebar-title">Article Categories</h3>
              <div className="sidebar-item tags">
                <ul>
                {artCategories.map(value => (
                  selectedCategory(value.catid)
                ))}
                </ul>
              </div>

            </div>

          </div>

        </div>

      </div>
    </section>
        </>
    );
}

export default FullArticlePage;