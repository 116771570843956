import React, { useContext } from "react";
import {
    Link
} from 'react-router-dom';

// Import requirements for the context API.
import { /*UserProvider,*/ UserContext, UserDispatchContext } from "../../../context/UserProvider";

const ArticleSummary = (props) => {

    const { userDetails, sportsAuthors, artCategories } = useContext(UserContext);
    const artCat = artCategories.filter(cat => cat.catid === props.catid);
    const artAuth = sportsAuthors.filter(auth => auth.authorid === props.author);

    return(<>
            {/* <div className="card col-md-6 col-lg-4">
                <div className="card-header">
                    <Link to={"/publications/article/"+props.keyval}><img className="artcle-image" src={props.picture} alt={props.name} /></Link>
                </div>
                <div className="card-body">
                    <Link to={"/publications/"+artCat[0].keyval}><span className="tag tag-blue">{artCat[0].name}</span></Link>
                    <Link to={"/publications/article/"+props.keyval}><h4 className="article-title">{props.name}</h4></Link>
                    <p>
                        {(props.subArt).substring(0,150)}...
                    </p>
                    <div className="user">
                        <img src={artAuth[0].avat} alt={artAuth[0].name} />
                        <div className="user-info">
                            <h5>{artAuth[0].name}</h5>
                            <small>{props.date}</small>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className="col-md-4">
                <div className="card card-blog">
                    <div className="card-image">
                        <Link to={"/publications/article/"+props.keyval}><img className="img" src={props.picture} alt={props.name} /></Link>
                        <div className="ripple-cont"></div>
                    </div>
                    <div className="table">
                        <Link to={"/publications/"+artCat[0].keyval}><h6 className="category text-success"><i className="bi bi-collection-fill"></i> {artCat[0].name}</h6></Link>
                        <h4 className="card-caption">
                            <Link to={"/publications/article/"+props.keyval}>{props.name}</Link>
	    			    </h4>
                        <p className="card-description">{(props.subArt).substring(0,150)}...</p>
                        <div className="ftr">
                            <div className="author">
                                <a href="#"> <img src={artAuth[0].avat} alt={artAuth[0].name} className="avatar img-raised" /> <span>{artAuth[0].name}</span> </a>
                            </div>
                            <div className="stats"> <i className="fa fa-clock-o"></i> {props.date} </div>
                        </div>
                    </div>
                </div>
            </div>
    </>    
    );
}

export default ArticleSummary;