import React, { useState, useContext } from "react";
import axios from 'axios';
// Import requirements for the context API.
import { UserContext } from "../../../context/UserProvider";

const Contact = () => {

    const { configAxiosHeaders } = useContext(UserContext);
    const subscribeURL = "http://127.0.0.1:8000/api/subscribe";

    const [fields, setFields] = useState({
        firstName: "",
        surName: "",
        emailAddress: "",
        telephone: "",
        messageSubject: "",
        message: "",
        date: "",
        time: ""
    });

    const handleChange = (event) => {
        const {name, value} = event.target;
        setFields({ ...fields, [name]: value });
	}

    const handleSubmit = async (e) => {
        e.preventDefault();
        axios.post('http://127.0.0.1:8000/api/feedback', {
            fname: fields.firstName,
            lname: fields.surName,
            email: fields.emailAddress,
            telephone: fields.telephone,
            topic: fields.messageSubject,
            message: fields.message,
			date: new Date().toLocaleDateString(),
			time: new Date().toLocaleTimeString()
        }, configAxiosHeaders).then((response) => {
            // setResponse(response.data)
            // console.log(response.data);
            window.alert(response.data);
            setFields({
                firstName: "",
                surName: "",
                emailAddress: "",
                telephone: "",
                messageSubject: "",
                message: "",
                date: "",
                time: ""
            })
            // e.target.reset();
        }).catch((err) => { 
            if (err.response) { 
              // client received an error response (5xx, 4xx)
              console.log("Response error: "+err)
            } else if (err.request) { 
              // client never received a response, or request never left 
              console.log("Request error: "+err)
            } else { 
              // anything else 
            } 
        })
        // }).catch((err) => console.log(err))
    };

    return(
        <form role="form" className="php-email-form" onSubmit={handleSubmit}>
            <div className="row" style={{marginBottom: 16}}>
                <div className="col-md-6 form-group">
                    <input
                        type="text"
                        id="firstName"
                        name="firstName"
                        value={fields.firstName}
                        className="form-control"
                        placeholder="Your First Name"
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="col-md-6 form-group mt-3 mt-md-0">
                    <input
                        type="text"
                        id="surName"
                        name="surName"
                        value={fields.surName}
                        className="form-control"
                        placeholder="Your Surname"
                        onChange={handleChange}
                        required
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 form-group">
                    <input
                        type="email"
                        id="emailAddress"
                        name="emailAddress"
                        value={fields.emailAddress}
                        className="form-control"
                        placeholder="Your Email (Optional)"
                        onChange={handleChange}
                        // required
                    />
                </div>
                <div className="col-md-6 form-group mt-3 mt-md-0">
                    <input
                        type="text"
                        id="telephone"
                        name="telephone"
                        value={fields.telephone}
                        className="form-control"
                        placeholder="Your Phone Contact (Optional)"
                        onChange={handleChange}
                        // required
                        />
                </div>
            </div>
            <div className="form-group mt-3">
                <input
                    type="text"
                    id="messageSubject"
                    name="messageSubject"
                    value={fields.messageSubject}
                    className="form-control"
                    placeholder="Subject"
                    onChange={handleChange}
                    required
                />
            </div>
            <div className="form-group mt-3">
                <textarea
                    id="message"
                    name="message"
                    value={fields.message}
                    rows="5"
                    className="form-control"
                    placeholder="Message"
                    onChange={handleChange}
                    required
                />
            </div>
            <div className="my-3">
                <div className="loading">Loading</div>
                <div className="error-message"></div>
                <div className="sent-message">Your message has been sent. Thank you!</div>
            </div>
            <div className="text-center"><button type="submit">Send Message</button></div>
        </form>
    );
}

export default Contact;