import React, { useContext } from "react";
import {
  Link,
  useParams,
} from 'react-router-dom';

import Article from "../publications/articles/Article";
import { Articles } from "../publications/articles/Articles";
// Import requirements for the context API.
import { UserContext } from "../../context/UserProvider";

const ProjBanner = (props) => {

    // console.log(selectArt[0].title)
    // const sortedArticles = Articles.sort((published, b) => b.date - published.date);
    // console.log("Articles sorted by date:"+sortedArticles[1].title+" "+sortedArticles[1].published);
    // window.alert("Latest Article Details: "+newestArt[0].title);
    // window.alert("Current.date is: "+new Date() - (selectArt[0].published).date)

    return(
        <>
            <section id="project-banner-section" className="justify-content-center align-items-center">
                <div className="container position-relative">
                    <div className="row justify-content-center">
                        <div className="col-xl-12">
                            <h2>{props.name}</h2>
                        </div>
                    </div>
                </div>
            </section>  
        </>    
    );
}

export default ProjBanner;