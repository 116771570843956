import React from "react";
import {
  Link
} from 'react-router-dom';
// Import Page Sections.
import Project from "../../components/projects/Project";
// Importing resources.
import Transitions from "../../components/nav/Transition";
import { Projects } from '../../components/projects/Projects';
import { motionValue } from "framer-motion";

const ProjectPage = () => {

    // Projects.
    // const selectPro = Projects.filter(pro => pro.id === props.id);

    return(
        <>
            <Transitions>
                <section className="breadcrumbs">
                    <div className="container">
                        <div className="d-flex justify-content-between align-items-center">
                            <h2>Our Projects</h2>
                            <ol>
                                <li><Link to="/">Home</Link></li>
                                <li>Our Projects</li>
                            </ol>
                        </div>
                        <div className="section-title">
                            <h2>Our Projests.</h2>
                        </div>
                        <div className="row">
                        {Projects.map(value => (
                            <Project
                                pic={value.image}
                                name={value.naame}
                                keyvalue={value.keyv}
                            />
                        ))}
                        </div>
                    </div>
                </section>
            </Transitions>
        </>
    );
}

export default ProjectPage;