import React from "react";
// Importing resources.
import Transitions from "../components/nav/Transition";
// Import page sections.
import Banner from "../components/banner/Banner";
import ProjectSummary from "../components/projects/ProjectSummary";

const Homepage = () => {
    return(
        <>
            <Transitions>
                <Banner />
                <ProjectSummary />
                {/* <section className="why-us section-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 video-box">
                                <img src="assets/img/why-us.jpg" className="img-fluid" alt="" />
                                <a href="https://www.youtube.com/watch?v=jDDaplaOz7Q" className="venobox play-btn mb-4" data-vbtype="video" data-autoplay="true"></a>
                            </div>
                            <div className="col-lg-6 d-flex flex-column justify-content-center p-5">
                                <div className="icon-box">
                                    <div className="icon"><i className="bx bx-fingerprint"></i></div>
                                    <h4 className="title"><a href="">Our Vision.</a></h4>
                                    <p className="description">To create a platform for advocacy on legal issues in the sports sector in Uganda and Africa at large.</p>
                                </div>
                                <div className="icon-box">
                                    <div className="icon"><i className="bx bx-gift"></i></div>
                                    <h4 className="title"><a href="">Our Mission.</a></h4>
                                    <p className="description">Using the law as a tool of advocacy for a better sports industry through research and publication.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
            </Transitions>
        </>
    );
}

export default Homepage;