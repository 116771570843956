import React, { useContext } from "react";
import {
    Link
} from 'react-router-dom';

// Import requirements for the context API.
import { /*UserProvider,*/ UserContext, UserDispatchContext } from "../../context/UserProvider";

const ProjectSummary = (props) => {

    return(
        <section className="breadcrumbs">
            <div className="container">
                <div className="section-title">
                    <h2>Our Projects.</h2>
                </div>
                <div className="row">
                    <div className="col-md-5 order-1 order-md-2">
                        <img src="https://www.justsportsug.org/assetweb/images/projects/all-saints-centenary-run.jpg" style={{borderRadius: 7}} className="img-fluid" alt="All Saints Centenary Run" />
                    </div>
                    <div style={{marginTop: 0}} className="col-md-7 pt-5 order-2 order-md-1">
                        <h3>The All Saints Centenary Run</h3>
                        <p className="fst-italic">
                            JustsportsUg partners with All Saint’s Church Manyangwa (ASCM) to organize the All-Saint’s Church Centenary Run.
                        </p>
                        <p>
                        As part of JustsportsUg's commitment to use sports as tool of community engagement, we partnered with All Saints Church Manyangwa (ASCM) to organize the All-Saint’s Church Centenary Run (ASCCM). First, forward ASCM is community church found in Manyangwa, Wakiso district found in the central region of Uganda. The church has a distinct and unique history having been constructed by Sir Apollo Kaggwa, undoubtedly the most famous Katikiiro of Buganda Kingdom.
                        </p>
                        <div className="read-more">
                            <Link to={"/projects/all-saints-centenary-run"}>Read More</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ProjectSummary;