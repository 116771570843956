import React, { useContext } from "react";
import {
  Link
} from 'react-router-dom';
// Import page sections.
import Newsletter from "../contact/Newsletter";
// Import requirements for the context API.
import { UserContext } from "../../context/UserProvider";

const Footer = () => {

    // const { artCategories } = useContext(UserContext);

    return(
        <footer id="footer" /*data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-duration="500"*/>
            <Newsletter />

            <div className="footer-top">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-6 footer-links">
                            <h4>Useful Links</h4>
                            <ul>
                                <li><i className="bx bx-chevron-right"></i> <Link to="/">Home</Link></li>
                                {/* <li><i className="bx bx-chevron-right"></i> <a href="team.html">Our Authors</a></li> */}
                                <li><i className="bx bx-chevron-right"></i> <Link to="publications">Our Publications</Link></li>
                                <li><i className="bx bx-chevron-right"></i> <Link to="projects">Our Projects</Link></li>
                                <li><i className="bx bx-chevron-right"></i> <Link to="about-us">About us</Link></li>
                                {/* <li><i className="bx bx-chevron-right"></i> <Link>Our Projects</Link></li> */}
                                <li><i className="bx bx-chevron-right"></i> <Link to="contact-us">Contact Us</Link></li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-6 footer-links">
                            <h4>Resources</h4>
                            <ul>
                                <li><i className="bx bx-chevron-right"></i> <Link /*to={"/publications/"+value.keyval}*/>Privacy Policy</Link></li>
                            </ul>
                        </div>
                        <div className="col-lg-3 col-md-6 footer-contact">
                            <h4>Contact Us</h4>
                            <p>
                                {/* A108 Adam Street <br />
                                New York, NY 535022<br />
                                United States <br /><br /> */}
                                <strong>Phone:</strong> +256 757 527892<br />
                                <strong>Email:</strong> enquiry@justsport.org<br />
                            </p>
                        </div>
                        <div className="col-lg-3 col-md-6 footer-info">
                            <h3>About Just Sports</h3>
                            <p>
                                <h6>Our Vision</h6>
                                To create a platform for advocacy on legal issues in the sports sector in Uganda and Africa at large.
                                <br /><br />
                                <h6>Our Mission</h6>
                                Using the law as a tool of advocacy for a better sports industry through research and publication.
                            </p>
                            <div className="social-links mt-3">
                                <a href="#" className="twitter"><i className="bx bxl-twitter"></i></a>
                                <a href="#" className="facebook"><i className="bx bxl-facebook"></i></a>
                                <a href="#" className="instagram"><i className="bx bxl-instagram"></i></a>
                                <a href="https://ug.linkedin.com/in/just-sports-5876362275" className="linkedin"><i className="bx bxl-linkedin"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="copyright">
                    &copy; Copyright <strong><span>Just Sports</span></strong>. All Rights Reserved
                </div>
                <div className="credits">
                    {/* All the links in the footer should remain intact.
                    You can delete the links only if you purchased the pro version.
                    Licensing information: https://bootstrapmade.com/license/
                    Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/free-bootstrap-template-corporate-moderna/
                    Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a> */}
                </div>
            </div>
        </footer>
    );
}

export default Footer;