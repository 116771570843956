export let Projects = [
    {
        id: "JSP-01",
        keyv: "all-saints-centenary-run",
        image: "https://www.justsportsug.org/assetweb/images/projects/all-saints-centenary-run.jpg",
        naame: "All Saint`s Centenary Run",
        tiitle: "JustsportsUg partners with All Saint’s Church Manyangwa (ASCM) to organize the All-Saint’s Church Centenary Run.",
        date: "2023-04-19",
        desc: [<>
            <p>
            As part of JustsportsUg's commitment to use sports as tool of community engagement, we partnered with All Saints Church Manyangwa (ASCM) to organize the All-Saint’s Church Centenary Run (ASCCM). First, forward ASCM is community church found in Manyangwa, Wakiso district found in the central region of Uganda. The church has a distinct and unique history having been constructed by Sir Apollo Kaggwa, undoubtedly the most famous Katikiiro of Buganda Kingdom. His contribution did not only end towards Buganda Kingdom and the country through nation building having played a key role in the signing of the 1900 Buganda Agreement. He also made a generous contribution by constructing a church on part of his country side estate, the church has for the past 120 years not only served as a place of worship but also as a critical community institution that has played a key role in impacting the lives of many people within the surrounding community. The church boosts of a community school established in memory of Sir Apollo Kaggwa called Sir Apollo Kaggwa primary school and also a vocational secondary school.
            </p>
            <p>
            In 2019 the Church started on an ambitious project to construct a new church, and with the grace of God that process has progressed on well to date. At the start of this year the church also launched the celebrations to mark its centenary with a number of events lined up including the ASCCM, the aim of these events was to fundraise for the completion of its church construction project but also towards realization of its grand master plan.
            </p>
            <p>
            The church master plan project aims not only to cement the legacy its fore father the late Sir Apollo Kaggwa but also its present-day Christians, the project includes construction of a community hospital, upgrading of the primary school but also establishment of a community sports center. As Justsportsug we would like to thank one of our members Ssemyalo Wycliff for committing his efforts and organizing the ASCCM the aim of event was to fundraise towards the construction of the new church but also ensure the physical wellness of all Christians and the community at large.
            </p>
            <p>
            The event was successfully hosted on 26th / August / 2026 with participants taking part in a 5 kilo meters run around Manyangwa. As Justsportsug.org we would like to thank first All Saint’s Church through its Lay leader Mr. Mugooda Jousha and other partners like Uzima Water, NEC enterprises, Rotary Club of Gayaza and Peoples Medical Center Gayaza for having made it possible to have a successful event.
            </p>
            <p>
            As Justsportsug.org, we are committed to always support any efforts that aim to use sports as a tool of community engagement and development.
            </p>
        </>]//,
        // gal: [
        //     {
        //         path: 'https://www.edmundwalusimbi.com/react/images/gallery/ms/Edmund-as-the-MC-at-an-AIESEC-Event-1.jpg',
        //         name: 'Edmund as an MC at an AIESEC Event.'
        //     },
        // ]
    }]
    
    // module.exports = {
    //     TeamMembers
    // }