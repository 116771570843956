import React, { useState, useContext } from "react";
import axios from 'axios';
// Import requirements for the context API.
import { UserContext } from "../../../context/UserProvider";

const CommentForm = (props) => {

    const { configAxiosHeaders } = useContext(UserContext);

    const commentURL = "http://127.0.0.1:8000/api/comments";

    const [fields, setFields] = useState({
        vName: "",
        vEmail: "",
        website: "",
        vComment: "",
        refer: props.replyTo,
        date: "",
        time: ""
    });

    const handleChange = (event) => {
        const {name, value} = event.target;
        setFields({ ...fields, [name]: value });
	}

    const handleSubmit = async (e) => {
        e.preventDefault();
        const commentId = props.replyTo+"/"+(props.index)
        // axios({
        //     method: 'post',
        //     url: "http://127.0.0.1:8000/api/feedback",
        //     headers: { "Content-Type": "multipart/form-data" },
        //     data: formData,
        // })
        axios.post(commentURL, {
            comid: commentId,
            name: fields.vName,
            email: fields.vEmail,
            company: fields.website,
            comment: fields.vComment,
            replyto: fields.refer,
			date: new Date().toLocaleDateString(),
			time: new Date().toLocaleTimeString()
        }, configAxiosHeaders).then((response) => {
            // setResponse(response.data)
            // console.log(response.data);
            window.alert("Your comment has been posted successfully.");
            setFields({
                vName: "",
                vEmail: "",
                website: "",
                vComment: "",
                date: "",
                time: ""
            })
            // e.target.reset();
        }).catch((err) => { 
            if (err.response) { 
              // client received an error response (5xx, 4xx)
              console.log("Response error: "+err)
            } else if (err.request) { 
              // client never received a response, or request never left 
              console.log("Request error: "+err)
            } else { 
              // anything else 
            } 
          })
    };

    return(
        <div className="reply-form" onSubmit={handleSubmit}>
            <h4>Leave a Comment</h4>
            <p>Your email address will not be published. Required fields are marked * </p>
            <form>
                <div className="row">
                    <div className="col-md-6 form-group">
                        <input
                            type="text"
                            id="vName"
                            name="vName"
                            value={fields.vName}
                            className="form-control"
                            placeholder="Your Name*"
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="col-md-6 form-group">
                        <input
                            type="email"
                            id="vEmail"
                            name="vEmail"
                            value={fields.vEmail}
                            className="form-control"
                            placeholder="Your Email*"
                            onChange={handleChange}
                            required
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col form-group">
                        <input
                            type="text"
                            id="website"
                            name="website"
                            value={fields.website}
                            className="form-control"
                            placeholder="Your Organization (OPTIONAL)"
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col form-group">
                        <textarea
                            id="vComment"
                            name="vComment"
                            value={fields.vComment}
                            className="form-control"
                            placeholder="Your Comment*"
                            onChange={handleChange}
                            required
                        />
                    </div>
                </div>
                <button type="submit" className="btn btn-primary">Post Comment</button>
            </form>
        </div>
    );
}

export default CommentForm;