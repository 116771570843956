import React, { useContext } from "react";
import {
  Link,
  useParams,
} from 'react-router-dom';

import Article from "../publications/articles/Article";
import { Articles } from "../publications/articles/Articles";
// Import requirements for the context API.
import { UserContext } from "../../context/UserProvider";

// The importations below are temporary and need to be removed shortly..
import NoImage from "../../assets/images/noimage.png";

const Banner = () => {

    const { artCategories } = useContext(UserContext);

    // Latest article.
    const latestArt = new Date(Math.max(...Articles.map(e => new Date(e.published))));
    const newestArt = Articles.filter(art => {
        const pub = new Date (art.published);
        return pub.getTime()  === latestArt.getTime();
    });

    // Older articles.
    const olderArt = Articles.filter(art => art.id !== newestArt[0].id);

    // console.log(selectArt[0].title)
    const sortedArticles = Articles.sort((published, b) => b.date - published.date);
    // console.log("Articles sorted by date:"+sortedArticles[1].title+" "+sortedArticles[1].published);
    // window.alert("Latest Article Details: "+newestArt[0].title);
    // window.alert("Current.date is: "+new Date() - (selectArt[0].published).date)

    return(
        <>
            <section id="banner-section" className="justify-content-center align-items-center">
                <div className="container position-relative">
                    <div className="row justify-content-center">
                        <div className="col-xl-8">
                            <h2>Welcome to <span>Just Sports Ug</span></h2>
                            <p>Advocacy for Better Sports.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section id="blog" className="blog">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 entries">
                            <Article
                                keyval={newestArt[0].keyvalue}
                                picture={newestArt[0].image}
                                title={newestArt[0].title}
                                published={newestArt[0].published}
                            />
                        </div>
                        <div class="col-lg-4">
                            <div class="sidebar article-sidebar">
                                <h3 class="sidebar-title">Other Articles</h3>
                                    <div class="sidebar-item recent-posts">

                                    {olderArt.map(value => (
                                        <div class="post-item clearfix">
                                            <img src={value.image} alt={value.title} />
                                            <h4><Link to={"/publications/article/"+value.keyvalue}>{value.title}</Link></h4>
                                            <time datetime={value.published}>{value.published}</time>
                                        </div>
                                    ))}
                                    {/* Above we need a total of six side articles. */}

                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
        </>    
    );
}

export default Banner;