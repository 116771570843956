import React from "react";
import {
  Link
} from 'react-router-dom';
// Importing resources.
import Transitions from "../../components/nav/Transition";

const Project = (props) => {

    return(
        <div className="card float-right">
            <div className="row">
                <div className="col-sm-5">
                    <img className="d-block w-100" src={props.pic} alt={props.name} />
                </div>
                <div className="col-sm-7">
                    <div className="card-block">
                        {/* <h4 className="card-title">Small card</h4> */}
                        <h3>{props.name}</h3>
                        <p>
                            As part of JustsportsUg's commitment to use sports as tool of community engagement, we partnered with All Saints Church Manyangwa (ASCM) to organize the All-Saint’s Church Centenary Run (ASCCM). First, forward ASCM is community church found in Manyangwa, Wakiso district found in the central region of Uganda. The church has a distinct and unique history having been constructed by Sir Apollo Kaggwa, undoubtedly the most famous Katikiiro of Buganda Kingdom.
                        </p>
                        <br />
                        <Link to={"/projects/"+props.keyvalue} className="btn btn-primary btn-sm float-right">Read More</Link>
                    </div>
                </div>
            </div>
        </div>        
    );
}

export default Project;