import React from "react";
import {
    Routes,
    Route,
    useLocation
} from 'react-router-dom';
// PageTransitionEvent.
import { AnimatePresence } from 'framer-motion';
// Importing pages.
import Homepage from "./Homepage";
import AboutUsPage from "./AboutUsPage";
import PublicationsContainerPage from "./publications/PublicationsContainerPage";
import PublicationsPage from "./publications/PublicationsPage";
import ArticleCategoryDetailsPage from "./publications/ArticleCategoryDetailsPage";
import FullArticlePage from "./publications/FullArticlePage";
import ProjectContainerPage from "./projects/ProjectContainerPage";
import ProjectPage from "./projects/ProjectPage";
import ProjectDetailsPage from "./projects/ProjectDetailsPage";
import ContactUsPage from "./ContactUsPage";
import PageNotFound from "./PageNotFound";
import { UserProvider } from "../context/UserProvider";
// import AuthorsPage from "./AuthorsPage";

// Importing the context.
import { UserDispatchContext } from "../context/UserProvider";

const PageFormat = () => {
    const location = useLocation();

    return(
        <AnimatePresence>
            <UserProvider>
                <main id="main">
                    <Routes>
                        <Route path="/" element={<Homepage />} />
                        <Route path="publications" element={<PublicationsContainerPage />}>
                            <Route path="" element={<PublicationsPage />} />
                            <Route path=":keyId" element={<ArticleCategoryDetailsPage />} />
                            <Route path="article/:artId" element={<FullArticlePage />} />
                        </Route>
                        <Route path="projects" element={<ProjectContainerPage />}>
                            <Route path="" element={<ProjectPage />} />
                            {/* <Route path=":keyId" element={<ArticleCategoryDetailsPage />} /> */}
                            <Route path=":projId" element={<ProjectDetailsPage />} />
                        </Route>
                        <Route path="about-us" element={<AboutUsPage />} />
                        <Route path="contact-us" element={<ContactUsPage />} />
                        <Route path="/*" element={<PageNotFound />} />
                    </Routes>
                </main>
            </UserProvider>
        </AnimatePresence>
    );
}

export default PageFormat;