import React, { useContext } from "react";
import {
  Link
} from 'react-router-dom';
// Import page sections.
import ArticleCategory from "../../components/publications/ArticleCategory";
import ArticleSummary from "../../components/publications/articles/ArticleSummary";
// Importing resources.
import Transitions from '../../components/nav/Transition';

// Import requirements for the context API.
import { UserContext } from "../../context/UserProvider";

// Import articles.
import { Articles } from '../../components/publications/articles/Articles';

const PublicationsPage = () => {

    const { artCategories } = useContext(UserContext);

    return(
        <>
            <Transitions>
                <section className="breadcrumbs">
                    <div className="container">
                        <div className="d-flex justify-content-between align-items-center">
                            <h2>Our Publications</h2>
                            <ol>
                                <li><Link to="/">Home</Link></li>
                                <li>Our Publications</li>
                            </ol>
                        </div>
                    </div>
                </section>
                <section className="services">
                    <div className="container">
            
                        <div className="section-title">
                            <h2>Our Articles.</h2>
                            {/* <p>We have a number of articles all of which are organized in different categories. Please select any of the categories to from which you are interested in reading an article.</p> */}
                        </div>

                        <div className="row">
                        {Articles.map(value => (
                            <ArticleSummary
                                id={value.id}
                                keyval={value.keyvalue}
                                picture={value.image}
                                name={value.title}
                                catid={value.cat}
                                author={value.author}
                                subArt={value.audio}
                                date={value.published}
                            />
                        ))}
                        </div>

                        {/* <div className="row">
                        {artCategories.map(value => (
                            <ArticleCategory
                                id={value.catid}
                                keyvalue={value.keyval}
                                catname={value.name}
                                number={value.number}
                                contdelay={value.delay}
                                color={value.color}
                                selecticon={value.icon}
                            />
                        ))}
                        </div> */}
                    </div>
                </section>
            </Transitions>
        </>
    );
}

export default PublicationsPage;