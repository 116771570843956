import React, { createContext, useState/*, useEffect*/ } from "react";
// import axios from 'axios';

// Create two context:
// UserContext: to query the context state
// UserDispatchContext: to mutate the context state
const UserContext = createContext(undefined);
const UserDispatchContext = createContext(undefined);

// A "provider" is used to encapsulate only the
// components that needs the state in this context
function UserProvider({ children }) {

    const configAxiosHeaders = {
        // headers:{
            xsrfHeaderName: "X-XSRF-TOKEN", // change the name of the header to "X-XSRF-TOKEN" and it should works
            withCredentials: true,
            // 'X-Requested-With': 'XMLHttpRequest',
            // 'X-CSRF-TOKEN': "{{ csrf_token() }}",
            "Access-Control-Allow-Origin": "http://localhost:3000",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
            "Access-Control-Allow-Credentials": "true",
            "Accept":"application/json, text/plain, /","Content-Type": "multipart/form-data"
        // }
    };
    
    const [sportsAuthors, setSportsAuthors] = useState([{
        authorid: "JSA1",
        avat: "https://www.justsportsug.org/assetweb/images/team/ssemyalo-wyclif.jpg",
        name: "Ssemyalo Wycliff",
        email: "wssemyalo@justsportsug.org"
    },{
        authorid: "JSA2",
        avat: "https://www.justsportsug.org/assetweb/images/team/chemonges-joel.jpg",
        name: "Chemonges Joel",
        email: "jchemonges@justsportsug.org"
    },{
        authorid: "JSA3",
        avat: "https://www.justsportsug.org/assetweb/images/team/opio-isaac.jpg",
        name: "Opio Isaac",
        email: "iopio@justsportsug.org"
    },{
        authorid: "JSA4",
        avat: "https://www.justsportsug.org/assetweb/images/team/okaka-jeremiah.jpg",
        name: "Okaka Jeremiah",
        email: "jokaka@justsportsug.org"
    },{
        authorid: "JSA5",
        avat: "https://www.justsportsug.org/assetweb/images/team/nyakuni-nobert.jpg",
        name: "Nyakuni Nobert",
        email: "nobnyakuni@justsportsug.org"
    }]);

    const [artCategories, setArtCategories] = useState([{
        catid: "JSAC01",
        keyval: "anti-dopping",
        name: "Anti-Dopping",
        color: "pink",
        icon: "bxl-dribbble"
    },{
        catid: "JSAC02",
        keyval: "sports-health-&-psychology",
        name: "Sports Health & Psychology",
        color: "blue",
        icon: "bx-world"
    },{
        catid: "JSAC03",
        keyval: "intellectual-property-in-sports",
        name: "Intellectual Property in Sports",
        color: "cyan",
        icon: "bx-file"
    },{
        catid: "JSAC04",
        keyval: "covid-19",
        name: "COVID-19",
        color: "green",
        icon: "bx-tachometer"
    },{
        catid: "JSAC05",
        keyval: "athlete-rights",
        name: "Athlete Rights",
        color: "blue",
        icon: "bx-world"
    },{
        catid: "JSAC06",
        keyval: "human-rights-in-sports",
        name: "Human Rights in Sports",
        color: "pink",
        icon: "bxl-dribbble"
    },{
        catid: "JSAC07",
        keyval: "competition-&-anti-trust",
        name: "Competition / Anti-Trust",
        color: "cyan",
        icon: "bx-file"
    },{
        catid: "JSAC08",
        keyval: "dispute-resolution",
        name: "Dispute Resolution",
        color: "green",
        icon: "bx-tachometer"
    },{
        catid: "JSAC09",
        keyval: "employment",
        name: "Employment",
        color: "blue",
        icon: "bx-world"
    },{
        catid: "JSAC10",
        keyval: "betting-&-gambling",
        name: "Betting & Gambling",
        color: "pink",
        icon: "bxl-dribbble"
    },{
        catid: "JSAC11",
        keyval: "integrity-in-sports",
        name: "Integrity in Sports",
        color: "cyan",
        icon: "bx-file"
    },{
        catid: "JSAC12",
        keyval: "media",
        name: "Media",
        color: "green",
        icon: "bx-tachometer"
    },{
        catid: "JSAC13",
        keyval: "regulation-&-governance",
        name: "Regulation & Governance",
        color: "blue",
        icon: "bx-world"
    },{
        catid: "JSAC14",
        keyval: "safe-guarding-&-guardians",
        name: "Safe Guarding / Guardians",
        color: "pink",
        icon: "bxl-dribbble"
    },{
        catid: "JSAC15",
        keyval: "tax",
        name: "Tax",
        color: "cyan",
        icon: "bx-file"
    },{
        catid: "JSAC16",
        keyval: "sport-governance",
        name: "Sport Governance",
        color: "green",
        icon: "bx-tachometer"
    }]);

    // const [artCategories, setArtCategories] = useState([]);
    // const [sportsAuthors, setSportsAuthors] = useState([]);
    const [playingAudio, setPlayingAudio] = useState({
        playing: false,
        article: ""
    });

    // useEffect(() => {
    //     axios
    //       .get("http://127.0.0.1:8000/api/categories")
    //       .then((result) => {
    //         // console.log("Feedback: "+(result.data[0]));
    //         setArtCategories(result.data[0]);
    //       })
    //     //   .catch((error) => {
    //     //     if (error.response) {
    //     //         // The request was made and the server responded with a status code
    //     //         // that falls out of the range of 2xx
    //     //         console.log(error.response.data);
    //     //         console.log(error.response.status);
    //     //         console.log(error.response.headers);
    //     //       } else if (error.request) {
    //     //         // The request was made but no response was received
    //     //         // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    //     //         // http.ClientRequest in node.js
    //     //         console.log(error.request);
    //     //       } else {
    //     //         // Something happened in setting up the request that triggered an Error
    //     //         console.log('Error', error.message);
    //     //       }
    //     //       console.log(error.config);
    //     //     // console.log(error)
    //     // });
    //       .catch((error) => console.log(error));

    //       axios
    //       .get("http://127.0.0.1:8000/api/authors")
    //       .then((authresult) => {
    //         // console.log("Author Feedback: "+(authresult.data[0])[0].updated_at);
    //         setSportsAuthors(authresult.data[0]);
    //       })
    //       .catch((error) => console.log(error));
    //   }, []);
      
  const [userDetails, setUserDetails] = useState({
    username: "John Doe"
  });

  return (
    // Need to figure out how to pass on more than 1 variable.
    <UserContext.Provider value={{ configAxiosHeaders, userDetails, sportsAuthors, artCategories, playingAudio }}>
      <UserDispatchContext.Provider value={{ setUserDetails, setSportsAuthors, setArtCategories, setPlayingAudio }}>
        {children}
      </UserDispatchContext.Provider>
    </UserContext.Provider>
  );
}

export { UserProvider, UserContext, UserDispatchContext };