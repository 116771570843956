import React from "react";
import {
  Link
} from 'react-router-dom';
// Importing page sections.
import Contact from "../components/contact/forms/Contact";
// Importing resources.
import Transitions from "../components/nav/Transition";

const ContactUsPage = () => {
    return(
        <>
            <Transitions>
                <section className="breadcrumbs">
                    <div className="container">
                        <div className="d-flex justify-content-between align-items-center">
                            <h2>Contact Us</h2>
                            <ol>
                                <li><Link to="/">Home</Link></li>
                                <li>Contact Us</li>
                            </ol>
                        </div>
                    </div>
                </section>
                <section class="contact" /*data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-duration="500"*/>
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="row">
                                    {/* <div class="col-md-12">
                                        <div class="info-box">
                                            <i class="bx bx-map"></i>
                                            <h3>Our Address</h3>
                                            <p>A108 Adam Street, New York, NY 535022</p>
                                        </div>
                                    </div> */}
                                    <div class="col-md-6">
                                        <div class="info-box">
                                            <i class="bx bx-envelope"></i>
                                            <h3>Email Us</h3>
                                            <p>enquiry@justsport.org</p>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="info-box">
                                            <i class="bx bx-phone-call"></i>
                                            <h3>Call Us</h3>
                                            <p>+256 757 527892</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <Contact />
                            </div>
                        </div>
                    </div>
                </section>
            </Transitions>
        </>
    );
}

export default ContactUsPage;