import React, { useState, useContext } from "react";
import axios from 'axios';
// Import requirements for the context API.
import { UserContext } from "../../../context/UserProvider";

const Subscribe = () => {

    // const { configAxiosHeaders } = useContext(UserContext);
    const subscribeURL = "http://127.0.0.1:8000/api/subscribe";

    const [fields, setFields] = useState({
        emailAddress: "",
        date: "",
        time: ""
    });

    const handleChange = (event) => {
        const {name, value} = event.target;
        setFields({ ...fields, [name]: value });
	}

    const handleSubmit = async (e) => {
        e.preventDefault();
        axios.post(subscribeURL, {
            email: fields.emailAddress,
			date: new Date().toLocaleDateString(),
			time: new Date().toLocaleTimeString()
        }/*, configAxiosHeaders*/).then((response) => {
            // setResponse(response.data)
            // console.log(response.data);
            window.alert(response.data);
            setFields({
                emailAddress: "",
                date: "",
                time: ""
            })
            // e.target.reset();
        // }).catch((err) => window.alert(err))
        }).catch((err) => { 
            if (err.response) { 
              // client received an error response (5xx, 4xx)
              console.log("Response error: "+err)
            } else if (err.request) { 
              // client never received a response, or request never left 
              console.log("Request error: "+err)
            } else { 
              // anything else 
            } 
        })
    };

    return(
        <form onSubmit={handleSubmit}>
            <input
                type="email"
                id="emailAddress"
                name="emailAddress"
                value={fields.emailAddress}
                onChange={handleChange}
            />
            <input
                type="submit"
                value="Subscribe"
            />
        </form>
    );
}

export default Subscribe;