import React, { useContext } from 'react';
import {
  Link,
  useParams,
} from 'react-router-dom';
// Importing page sections.
import Article from '../../components/publications/articles/Article';
// Importing resources.
import Transitions from '../../components/nav/Transition';
import { Articles } from '../../components/publications/articles/Articles';

// Import requirements for the context API.
import { UserContext } from "../../context/UserProvider";

const ArticleCategoryDetailsPage = () => {

    const { keyId } = useParams();
    const { artCategories } = useContext(UserContext);
    // Article category details.
    const selectCat = artCategories.filter(cat => cat.keyval === keyId);
    const unselectCat = artCategories.filter(catt => catt.keyval !== keyId);
    // Articles.
    const selectArt = Articles.filter(art => art.cat === selectCat[0].catid);

    function noArticles(artNo) {
        if(artNo > 0){
            return (
                <>
                    {selectArt.map(value => (
                        <Article
                            keyval={value.keyvalue}
                            picture={value.image}
                            title={value.title}
                            published={value.published}
                            category={selectCat[0].name}
                            catval={selectCat[0].keyval}
                        />
                    ))}
                    {/* <div className="blog-pagination">
                        <ul className="justify-content-center">
                            <li><a href="#">1</a></li>
                            <li className="active"><a href="#">2</a></li>
                            <li><a href="#">3</a></li>
                        </ul>
                    </div> */}
                </> 
            )
        }else{
            return (
                <div className="section-title">
                        <h2 style={{color: 'red'}}>No articles yet.</h2>
                        <p>
                            We are yet to upload any articles in the <b>{selectCat[0].name}</b> category. In the meantime, please <Link to="/publications">click here</Link> to access the articles in the other categories.
                        </p>
                </div>
            )
        }
    }

    function selectedCategory(catId){
        const listCat = artCategories.filter(cat => cat.catid === catId);

        if(catId === selectCat[0].catid){
            return (
                <li className='selected-category'><Link to={"/publications/"+listCat[0].keyval}>{listCat[0].name}</Link></li>
            )
        }else {
            return (
                <li><Link to={"/publications/"+listCat[0].keyval}>{listCat[0].name}</Link></li>
            )
        }
    }

    return (
        <>
            <Transitions>
                <section className="breadcrumbs">
                    <div className="container">
                        <div className="d-flex justify-content-between align-items-center">
                        <h2>{selectCat[0].name}</h2>
                        <ol>
                            <li><Link to="/">Home</Link></li>
                            <li><Link to="/publications">Our Publications</Link></li>
                            <li>{selectCat[0].name}</li>
                        </ol>
                        </div>
                    </div>
                </section>
                <section id="blog" className="blog">
                    <div className="container" /*data-aos="fade-up"*/>
                        <div className="row">
                            <div className="col-lg-8 entries">
                                {noArticles(selectArt.length)}
                                {/* <Article /> */}
                            </div>
                            <div className="col-lg-4">
                                <div className="sidebar article-sidebar">
                                    <h3 className="sidebar-title">Search by title</h3>
                                    <div className="sidebar-item search-form">
                                        <form action="">
                                            <input type="text" />
                                            <button type="submit"><i className="bi bi-search"></i></button>
                                        </form>
                                    </div>
                                    {/* <h3 className="sidebar-title">Our Authors</h3>
                                    <div className="sidebar-item categories">
                                        <ul>
                                            <li><a href="#">Ssemyalo Wycliff <span>(0)</span></a></li>
                                            <li><a href="#">Chemonges Joel <span>(0)</span></a></li>
                                            <li><a href="#">Opio Isaac <span>(0)</span></a></li>
                                            <li><a href="#">Okaka Jeremiah <span>(1)</span></a></li>
                                        </ul>
                                    </div> */}
                                    {/* <h3 className="sidebar-title">Recent Articles</h3>
                                    <div className="sidebar-item recent-posts">
                                        <div className="post-item clearfix">
                                        <img src="assets/img/blog/blog-recent-1.jpg" alt="" />
                                        <h4><a href="blog-single.html"> at in nihil autem</a></h4>
                                        <time datetime="2020-01-01">Jan 1, 2020</time>
                                        </div>

                                        <div className="post-item clearfix">
                                        <img src="assets/img/blog/blog-recent-2.jpg" alt="" />
                                        <h4><a href="blog-single.html">Quidem autem et impedit</a></h4>
                                        <time datetime="2020-01-01">Jan 1, 2020</time>
                                        </div>

                                        <div className="post-item clearfix">
                                        <img src="assets/img/blog/blog-recent-3.jpg" alt="" />
                                        <h4><a href="blog-single.html">Id quia et et ut maxime similique occaecati ut</a></h4>
                                        <time datetime="2020-01-01">Jan 1, 2020</time>
                                        </div>

                                        <div className="post-item clearfix">
                                        <img src="assets/img/blog/blog-recent-4.jpg" alt="" />
                                        <h4><a href="blog-single.html">Laborum corporis quo dara net para</a></h4>
                                        <time datetime="2020-01-01">Jan 1, 2020</time>
                                        </div>

                                        <div className="post-item clearfix">
                                        <img src="assets/img/blog/blog-recent-5.jpg" alt="" />
                                        <h4><a href="blog-single.html">Et dolores corrupti quae illo quod dolor</a></h4>
                                        <time datetime="2020-01-01">Jan 1, 2020</time>
                                        </div>

                                    </div> */}

                                    <h3 className="sidebar-title">Article Categories</h3>
                                    <div className="sidebar-item tags">
                                        <ul>
                                        {artCategories.map(value => (
                                            selectedCategory(value.catid)
                                        ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Transitions>
        </>
    );
}

export default ArticleCategoryDetailsPage;